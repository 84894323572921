<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" :button1="btn" />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Global NDA</h4>
            </div>

            <div class="auth-page-right-content">
              <div class="heading">
                <ThemeButton />
                <h6>Forgot Username</h6>
                <p v-if="errorMessage != null" class="error">
                  {{ errorMessage }}
                </p>
                <p v-else>
                  Please provide your account details to locate your username. 
                  We will send it to the email listed in your account.
                </p>
              </div>
              <form @submit.prevent @keyup.enter="forgotUsername(0)">
                <div class="form-row">
                  <div class="col-lg-12 pr-lg-6 p-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Business Email <span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control pl-3"
                    id="name3"
                    placeholder="Business Email associated with account"
                    autocomplete="off"
                    v-model.trim="$v.details.alternate_email.$model"
                    :class="{
                      'is-invalid': $v.details.alternate_email.$error,
                      'is-valid': !$v.details.alternate_email.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip mt-3">
                    <span v-if="!$v.details.alternate_email.required"
                      >Business email is required</span
                    >
                    <span v-if="!$v.details.alternate_email.email"
                      >Business email must be valid
                    </span>
                  </div>
                </div>
              </div>
                  <div class="form-group col-lg-6 px-0">
                    <v-select
                      v-model.trim="$v.details.question.$model"
                      :options="questionList"
                      label="name"
                      :placeholder="questionPlaceholder"
                      @input="setSelected"
                      class="custom-select p-0"
                      :class="{
                        'is-invalid': $v.details.question.$error,
                        'is-valid': !$v.details.question.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.question.required"
                        >Security question is required</span
                      >
                    </div>
                  </div>
                  <div class="form-group col-lg-6 px-0 pl-lg-2">
                    <input
                      type="text"
                      class="form-control pl-3"
                      id="answer"
                      placeholder="Security Answer"
                      autocomplete="off"
                      v-model.trim="$v.details.answer.$model"
                      :class="{
                        'is-invalid': $v.details.answer.$error,
                        'is-valid': !$v.details.answer.$invalid,
                      }"
                    />
                    <div class="invalid-tooltip">
                      <span v-if="!$v.details.answer.required"
                        >Security answer is required</span
                      >
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  class="auth-btn"
                  @click="forgotUsername(0)"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Next</span>
                </button>
                <div class="account d-md-none">
                  <router-link to="/register" class="forgot-password"
                    >Register</router-link
                  >
                </div>
              </form>
              <div class="account">
                <router-link to="/login" class="forgot-password"
                  >Login</router-link
                >
              </div>
            </div>
            
            <div class="circle-one d-block d-md-none">
              <img src="../../../assets/images/circle1.png" />
            </div>
            <div class="circle-two d-block d-md-none">
              <img src="../../../assets/images/circle2.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import ThemeButton from "../../../components/Theme/ThemeButton.vue";
import { mapGetters, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import AuthLeft from "../../../components/authleft/AuthLeft.vue";
let questions = require("../../../assets/js/questions");
export default {
  name: "ForgotUsername",

  components: {
    ThemeButton,
    AuthLeft,
  },

  data: function() {
    return {
      errorMessage: null,
      isLoadingArray: [],
      details: {
        question: "",
        answer: "",
        alternate_email:"",
      },
      btn: "Register",
      para: "Simplify your NDA. But first, let’s reset your username.",
      questionList: questions,
      questionPlaceholder: "Select security question",
    };
  },
  validations: {
    details: {
      question: {
        required,
      },
      answer: {
        required,
      },
      alternate_email: {
        required,
        email
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendResetPasswordRequest"]),

    forgotUsername: function(index) {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      var formData = new FormData();
      this.$set(this.isLoadingArray, index, true);
      formData.append("secret_question", this.details.question);
      formData.append("secret_answer", this.details.answer);
      formData.append("alternate_email", this.details.alternate_email);
      axios
        .post(process.env.VUE_APP_API_URL + "forgortEmail", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$swal("Email Sent!", response.data.message, "success");
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = "Please Check Your alternative email to get your username!"
          }else{
            this.errorMessage = response.data.message;
            this.$set(this.isLoadingArray, index, false);
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },

    setSelected(value) {
      this.details.question = value.name;
    },
  },
};
</script>
