<template>
  <div class="auth-page-left">
    <div class="content">
      <div class="logo">
        <img class="navlogo" src="../../assets/images/globalnda_caps-yellow-cutout.png" alt="GlobalNDA" />   
        <img class="nda d-none" src="../../assets/images/globalnda-dark-yellow.png" alt="GlobalNDA" /> 
      </div>
      <div class="text">
        <p>{{ paragraph }}</p>
        <p>{{ member }}</p>
        <router-link to="/register" class="btn" v-if="showRoute == 1">
          {{ button1 }}
        </router-link>
        <router-link to="/login" class="btn" v-if="showRoute == 2">
          {{ button2 }}
        </router-link>
        <router-link to="/EnterpriseAdminRegister" class="btn ml-2" v-if="showRoute2 == 4">
          {{ button4 }}
        </router-link>
        <router-link to="/login" class="btn" v-if="showRoute == 3">
          {{ button3 }}
        </router-link>
        <button class="bg-white rounded px-3 py-1" v-if="btn">
          {{ button }}
        </button>
      </div>
    </div>
    <div class="circle-one">
      <img src="../../assets/images/circle1.png" />
    </div>
    <div class="circle-two">
      <img src="../../assets/images/circle2.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLeft",
  data: function() {
    return {
      showRoute: "",
      showRoute2:"",
      btn: "",
    };
  },
  props: ["paragraph", "member", "button1", "button2", "button3", "button", "button4"],
  mounted() {
    var linkUrl = window.location.pathname;
    var segment_array = linkUrl.split("/");
    var lastSegment = segment_array.pop();
    if (lastSegment == "login") {
      this.showRoute = 1;
      this.showRoute2 = 4;
    } else if (lastSegment == "register") {
      this.showRoute = 2;
    } else if (lastSegment == "verification-email") {
      this.showRoute = 3;
    } else if (
      lastSegment == "forgot-password" ||
      lastSegment == "forgot-username" ||
      lastSegment == "verify-email" ||
      lastSegment == "new-password"
    ) {
      this.showRoute = 1;
    } else if (lastSegment == "password-update") {
      this.btn.style.display = "none";
    }
  },
};
</script>

<style></style>
