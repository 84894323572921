<template>
  <img :src="btnText" id="change-mode" @click="toggleTheme" />
</template>

<script>
export default {
  name: "ThemeButton",
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
  },

  data() {
    return {
      userTheme: "light-theme",
      btnText: require("../../../assets/images/moon.png"),
    };
  },

  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.btnText = require("../../../assets/images/sun.png");
        this.setTheme("dark-theme");
      } else if (activeTheme === "dark-theme") {
        this.btnText = require("../../../assets/images/moon.png");
        this.setTheme("light-theme");
      }
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      if (theme === "light-theme") {
        this.btnText = require("../../../assets/images/moon.png");
      } else if (theme === "dark-theme") {
        this.btnText = require("../../../assets/images/sun.png");
      }
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
};
</script>

<style scoped>



</style>