module.exports = [
  { id: 1, name: "In what city were you born?"},
  { id: 2, name: "What is the name of your favorite pet?" },
  { id: 3, name: "What is your mother's maiden name?" },
  { id: 4, name: "What high school did you attend?" },
  { id: 5, name: "What was the name of your elementary school?" },
  { id: 6, name: "What was the make and model of your first car?" },
  { id: 7, name: "What was your favorite food as a child?" },
  { id: 8, name: "Where did you meet your current spouse?" },
  { id: 9, name: "What year was your father born?" },

];
